import {CreateControllerFn} from '@wix/yoshi-flow-editor'

import {isTemplateView} from '../../utils/flowAPI'

import {initReservationAddOnStorage, mockReservationAddOnStorage} from './storage'

const createController: CreateControllerFn = async ({flowAPI}) => {
  return {
    async pageReady() {
      const isEditor = flowAPI.environment.isEditor
      const isPreview = flowAPI.environment.isPreview
      const isTemplate = isTemplateView(flowAPI)

      const shouldMockStorage = isEditor || isPreview || isTemplate

      const storage = shouldMockStorage
        ? mockReservationAddOnStorage(flowAPI)
        : initReservationAddOnStorage(flowAPI)

      flowAPI.controllerConfig.setProps(storage)

      await storage.getReservationLocations()
    },
  }
}

export default createController
